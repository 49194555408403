.bgpagesignup {
  min-height: calc(100vh - (56px + 0px));
}

.signupdivcard {
  width: 100%;
  max-width: 400px;
  padding-left: 20px;
  padding-right: 20px;
}

.linksignup {
  color: #54577C;
}

.linksignup:hover {
  color: #2B9EB3;
}