.color-nav {
  background-color: #EDF5FC;
}

.navbar {
  -webkit-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

.nav-link {
  color: #54577C;
}

/*.navbar-brand:hover{
}*/

.nav-link:hover {
  color: #D64933;
}

.navbar-toggler {
  border-color: #D64933;
}

.navbar-nav .dropdown-menu {
  background-color: #EDF5FC;
}

.navbar-nav .dropdown-item {
  color: #54577C;
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
  color: #D64933;
}

.navbar-nav .nav-link:focus {
  color: #2B9EB3;
}

.navbarbrandname {
  color: #2B9EB3;
  font-weight: 700;
  text-shadow: -1px 0 #54577C, 0 1px #54577C, 1px 0 #54577C, 0 -1px #54577C;
  margin-left: 5px;
}

.offcanvascss {
  background-color: #EDF5FC;
  color: #2B9EB3;
}