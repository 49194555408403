.content-front:nth-child(2):after {
  content: '';
}

.content-inner {
  width: 100%;
  color: #fff;
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
  backface-visibility: hidden;
  transition: 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.fl-wrap {
  float: left;
  width: 100%;
  position: relative;
}

.content-inner .content-front,
.content-inner .content-back {
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-front {
  background: #292929;
  position: relative;
}

.content-inner>* {
  backface-visibility: hidden;
  transition: 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.cf-inner:before,
.cf-inner:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-color: rgba(255, 255, 255, 0.4);
  z-index: 10;
}

.cf-inner:before {
  top: 20px;
  left: 20px;
  border-left: 1px solid;
  border-top: 1px solid;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  background-repeat: repeat;
  background-origin: content-box;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
  z-index: 3;
}

.content-inner .cf-inner .inner {
  align-items: stretch;
  transform-style: preserve-3d;
  perspective: 1000px;
  backface-visibility: hidden;
  transform: translateZ(95px) scale(0.81);
  text-align: center;
  position: relative;
  z-index: 2;
}

.cf-inner:after {
  bottom: 20px;
  right: 20px;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.content-inner .cf-inner .inner h2 {
  position: relative;
  font-size: 22px;
  font-weight: 800;
  padding-bottom: 20px;
}

.content-inner .cf-inner .inner h2:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 10px;
  width: 20px;
  height: 2px;
  margin-left: -10px;
}

.content-inner .content-front li {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  margin: 0 4px;
}

.cf-inner:after {
  bottom: 20px;
  right: 20px;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.content-inner .content-front,
.content-inner .content-back {
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-inner .cf-inner .inner {
  align-items: stretch;
  transform-style: preserve-3d;
  perspective: 1000px;
  backface-visibility: hidden;
  transform: translateZ(95px) scale(0.81);
  text-align: center;
  position: relative;
  z-index: 2;
}

.content-inner:hover .content-front {
  transform: rotateY(-180deg);
}

.content-inner:hover .content-back {
  transform: rotateY(0deg);
}

.content-inner .content-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transform: rotateY(180deg);
  background-image: url("https://3.bp.blogspot.com/-piZWCW2uUbg/W2fPXxkWZgI/AAAAAAAAOu0/eydmMjTIqcwLMHEEr2H7imqoRTxMw4o9QCLcBGAs/s1600/among_trees_night_dribbble.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.content-inner .cf-inner {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  width: 100%;
  padding: 150px 20px;
}

.serv-price-wrap {
  background: hsla(0, 72%, 7%, 0.295);
  padding: 15px 30px;
  margin-top: 14px;
  font-size: 11px;
  letter-spacing: 2px;
}

.hoverlettershadow:hover {
  text-shadow: 2px 1px 1px#f9423a;
  transition: 1s;
}

.content-inner .cf-inner .inner h2:before {
  background: #f9423a;
}

.cf-inner:before,
.cf-inner:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 250px;
  border-color: white;
  z-index: 10;
}

.dec-icon {
  padding-bottom: 30px;
}

.content-back .cf-inner:before {
  top: 125px;
}

.content-back .cf-inner:after {
  bottom: 125px;
}