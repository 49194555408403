.home_title {
  text-transform: uppercase;
  font-size: 43px;
  font-weight: bolder;
  letter-spacing: 3px;
  margin: 0;
}

.lightcolortxt {
  color: #e0e0e0;
}
.darkcolortxt {
  color: #2B303A;
}
.lightcolortxt2 {
  color: aliceblue;
}
.darkcolortxt2 {
  color: #2B303A;
}

.cleardivbelowhome:before,
.cleardivbelowhome:after {
  content: " ";
  display: table;
}

.cleardivbelowhome:after {
  clear: both;
}

.pt-table {
  display: table;
  width: 100%;
  height: -webkit-calc(100vh - (56px + 0px));
  height: -moz-calc(100vh - (56px + 0px));
  height: -o-calc(100vh - (56px + 0px));
  height: -ms-calc(100vh - (56px + 0px));
  height: calc(100vh - (56px + 0px));
}

.pt-tablecell {
  display: table-cell;
  vertical-align: middle;
}

.primary,
.link:hover {
  color: #FA6862;
}

/*------------------------------------------------
	Start Styling
-------------------------------------------------*/
/*Init hexagon*/
.hexagon-item:first-child {
  margin-left: 0;
}

.hexagon-item {
  cursor: pointer;
  width: 200px;
  height: 173.20508px;
  float: left;
  margin-left: -29px;
  z-index: 0;
  position: relative;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

.hexagon-item:first-child {
  margin-left: 0;
}

.hexagon-item:hover {
  z-index: 1;
}

.hexagon-item:hover .hex-item:last-child {
  opacity: 1;
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

.hexagon-item:hover .hex-item:first-child {
  opacity: 1;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.hexagon-item:hover .hex-item:first-child div:before,
.hexagon-item:hover .hex-item:first-child div:after {
  height: 5px;
}

.hexagon-item:hover .hex-item div::before,
.hexagon-item:hover .hex-item div::after {
  background-color: #f9423a;
}

/*svg hexagon background on hover animation*/
.hexagon-item:hover .hex-content svg {
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
  transform: scale(0.97);
}

.page-home .hexagon-item:nth-last-child(1),
.page-home .hexagon-item:nth-last-child(2),
.page-home .hexagon-item:nth-last-child(3) {
  -webkit-transform: rotate(30deg) translate(87px, -80px);
  -moz-transform: rotate(30deg) translate(87px, -80px);
  -ms-transform: rotate(30deg) translate(87px, -80px);
  -o-transform: rotate(30deg) translate(87px, -80px);
  transform: rotate(30deg) translate(87px, -80px);
}

.hex-item {
  position: absolute;
  top: 0;
  left: 50px;
  width: 100px;
  height: 173.20508px;
}

.hex-item:first-child {
  z-index: 0;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hex-item:last-child {
  -webkit-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;
}

.hex-item div {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 100px;
  height: 173.20508px;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
}

.hex-item div::before,
.hex-item div::after {
  background-color: #1e2530;
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.hex-item div:before {
  top: 0;
}

.hex-item div:after {
  bottom: 0;
}

.hex-item div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.hex-item div:nth-child(2) {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  transform: rotate(60deg);
}

.hex-item div:nth-child(3) {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(120deg);
}

/*Main without hovering*/
.hex-content {
  display: block;
  height: 180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  transform: rotate(-30deg);
  width: 156px;
}

.hex-content .hex-content-inner {
  left: 50%;
  margin: -3px 0 0 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hex-content .icon {
  display: block;
  font-size: 36px;
  line-height: 30px;
  margin-bottom: 11px;
}

.hex-content .title {
  display: block;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
}

/*Background del hexagono*/
.hex-content svg {
  left: -7px;
  position: absolute;
  top: -13px;
  transform: scale(0.87);
  z-index: -1;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.darkcolortxt:hover {
  color: #2B303A;
}

.lightcolortxt:hover {
  color: #fff;
}

.page-home .hexagon-item:nth-last-child(1),
.page-home .hexagon-item:nth-last-child(2),
.page-home .hexagon-item:nth-last-child(3) {
  -webkit-transform: rotate(30deg) translate(87px, -80px);
  -moz-transform: rotate(30deg) translate(87px, -80px);
  -ms-transform: rotate(30deg) translate(87px, -80px);
  -o-transform: rotate(30deg) translate(87px, -80px);
  transform: rotate(30deg) translate(87px, -80px);
}

@media (min-width: 1200px) {
  .col-lg-offset-2mod {
    margin-left: 18%;
  }
}

@media (min-width: 1200px) {
  .col-lg-8mod {
    width: 66.66666667%;
  }
}

.hexagon-item:first-child {
  margin-left: 0;
}

.hexagon-item:hover .icon i {
  color: #f9423a;
  transition: 0.6s;
}

/*Blur letters inside hexagon*/
.hexagon-item:hover .title {
  -webkit-animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  -moz-animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  -ms-animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  -o-animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    -ms-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@-moz-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    -ms-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@-o-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    -ms-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@-ms-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    -ms-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    -ms-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    -ms-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

/*Not toot much to say about, just fix hexagon on mobile*/
@media only screen and (max-width: 767px) {
  .hexagon-item {
    float: none;
    margin: 0 auto 50px;
  }

  .hexagon-item:first-child {
    margin-left: auto;
  }

  .hexagon-item:last-child {
    margin: 0 auto 80px;
  }

  .page-home .hexagon-item:nth-last-child(1),
  .page-home .hexagon-item:nth-last-child(2),
  .page-home .hexagon-item:nth-last-child(3) {
    -webkit-transform: rotate(30deg) translate(0px, 0px);
    -moz-transform: rotate(30deg) translate(0px, 0px);
    -ms-transform: rotate(30deg) translate(0px, 0px);
    -o-transform: rotate(30deg) translate(0px, 0px);
    transform: rotate(30deg) translate(0px, 0px);
  }
}



