/*--------------------------------------------------------------
# CV Div Modes General
--------------------------------------------------------------*/
.lightmode-aboutme {
  background-color: rgba(255, 255, 255, 0.9);
  color: black !important;
}

.darkmode-aboutme {
  background-color: rgba(0, 0, 0, 0.9);
  color: #e0e0e0 !important;
}

.darkmode-aboutme .counts .count-box {
  background: rgba(255, 255, 255, 0.08);
}

.lightmode-aboutme .counts .count-box {
  background: rgba(0, 0, 0, 0.08);
}

.darkmode-aboutme .counts .count-box .headericon {
  background: rgba(255, 255, 255, 0.1);
}

.lightmode-aboutme .counts .count-box .headericon {
  background: rgba(0, 0, 0, 0.1);
}

.darkmode-aboutme .interests .icon-box {
  background: rgba(255, 255, 255, 0.08);
}

.lightmode-aboutme .interests .icon-box {
  background: rgba(0, 0, 0, 0.08);
}

.darkmode-aboutme .interests .icon-box:hover {
  background: rgba(255, 255, 255, 0.12);
}

.lightmode-aboutme .interests .icon-box:hover {
  background: rgba(0, 0, 0, 0.12);
}

.darkmode-aboutme .resume .resume-item h5 {
  background: rgba(255, 255, 255, 0.15);
}

.lightmode-aboutme .resume .resume-item h5 {
  background: rgba(0, 0, 0, 0.15);
}

.darkmode-aboutme .resume .resume-item {
  border-left: 2px solid rgba(255, 255, 255, 0.2);
}

.lightmode-aboutme .resume .resume-item {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.homesection {
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 70px;
}

.homesection .aboutpdbtn {
  padding: 30px !important;
}

@media (max-width: 768px) {
  .homesection {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.section-title p {
  margin: 0;
  margin: -15px 0 15px 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 20px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #f9423a;
  margin: 4px 10px;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
@media (min-width: 1400px) {
  .img-fluid {
    max-height: 450px;
  }

  .ppimgdiv {
    justify-content: center;
    display: flex;
  }
}

.about-me .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #2B9EB3;
}

.about-me .content ul {
  list-style: none;
  padding: 0;
}

.about-me .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.about-me .content ul strong {
  margin-right: 10px;
}

.about-me .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #2B9EB3;
  line-height: 0;
}

.about-me .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  border: 1px solid transparent;
  animation: 3s borderChange2 alternate infinite !important;
  -webkit-animation: 3s borderChange2 alternate infinite !important;
}

@-webkit-keyframes borderChange2 {
  0% {
    border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
    border-image-slice: 1
  }
  25% {
    border-image: linear-gradient(to bottom right, #2c90fc 0%, #b8fd33 25%, #fec837 50%, #fd1892 75%, #b827fc 100%);
    border-image-slice: 1
  }
  50% {
    border-image: linear-gradient(to bottom right, #b8fd33 0%, #fec837 25%, #fd1892 50%, #b827fc 75%, #2c90fc 100%);
    border-image-slice: 1
  }
  75% {
    border-image: linear-gradient(to bottom right, #fec837 0%, #fd1892 25%, #b827fc 50%, #2c90fc 75%, #b8fd33 100%);
    border-image-slice: 1
  }
  100% {
    border-image: linear-gradient(to bottom right, #fd1892 0%, #b827fc 25%, #2c90fc 50%, #b8fd33 75%, #fec837 100%);
    border-image-slice: 1
  }
}

@keyframes borderChange2 {
  0% {
    border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
    border-image-slice: 1
  }
  25% {
    border-image: linear-gradient(to bottom right, #2c90fc 0%, #b8fd33 25%, #fec837 50%, #fd1892 75%, #b827fc 100%);
    border-image-slice: 1
  }
  50% {
    border-image: linear-gradient(to bottom right, #b8fd33 0%, #fec837 25%, #fd1892 50%, #b827fc 75%, #2c90fc 100%);
    border-image-slice: 1
  }
  75% {
    border-image: linear-gradient(to bottom right, #fec837 0%, #fd1892 25%, #b827fc 50%, #2c90fc 75%, #b8fd33 100%);
    border-image-slice: 1
  }
  100% {
    border-image: linear-gradient(to bottom right, #fd1892 0%, #b827fc 25%, #2c90fc 50%, #b8fd33 75%, #fec837 100%);
    border-image-slice: 1
  }
}

.counts .count-box .headericon {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  padding: 12px;
  border-radius: 50px;
  line-height: 0;
}

.counts .count-box span {
  font-size: 27px;
  display: block;
  font-weight: 500;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.borderanim:hover {
  animation: 9s borderChange alternate infinite !important;
  -webkit-animation: 8s borderChange alternate infinite !important;
}

@media (max-width: 768px) {
  .counts .count-box:hover .borderanim {
    animation: 9s borderChange alternate infinite !important;
    -webkit-animation: 8s borderChange alternate infinite !important;
  }
}


@-webkit-keyframes borderChange {
  0% {text-shadow: 1px 0 #007bff, -1px 0 #007bff, 0 1px #007bff, 0 -1px #007bff, 1px 1px #007bff, -1px -1px #007bff, 1px -1px #007bff, -1px 1px #007bff;}
  5% {text-shadow: 1px 0 #6610f2, -1px 0 #6610f2, 0 1px #6610f2, 0 -1px #6610f2, 1px 1px #6610f2, -1px -1px #6610f2, 1px -1px #6610f2, -1px 1px #6610f2;}
  10% {text-shadow: 1px 0 #6f42c1, -1px 0 #6f42c1, 0 1px #6f42c1, 0 -1px #6f42c1, 1px 1px #6f42c1, -1px -1px #6f42c1, 1px -1px #6f42c1, -1px 1px #6f42c1;}
  15% {text-shadow: 1px 0 #e83e8c, -1px 0 #e83e8c, 0 1px #e83e8c, 0 -1px #e83e8c, 1px 1px #e83e8c, -1px -1px #e83e8c, 1px -1px #e83e8c, -1px 1px #e83e8c;}
  20% {text-shadow: 1px 0 #dc3545, -1px 0 #dc3545, 0 1px #dc3545, 0 -1px #dc3545, 1px 1px #dc3545, -1px -1px #dc3545, 1px -1px #dc3545, -1px 1px #dc3545;}
  25% {text-shadow: 1px 0 #fd7e14, -1px 0 #fd7e14, 0 1px #fd7e14, 0 -1px #fd7e14, 1px 1px #fd7e14, -1px -1px #fd7e14, 1px -1px #fd7e14, -1px 1px #fd7e14;}
  30% {text-shadow: 1px 0 #ffc107, -1px 0 #ffc107, 0 1px #ffc107, 0 -1px #ffc107, 1px 1px #ffc107, -1px -1px #ffc107, 1px -1px #ffc107, -1px 1px #ffc107;}
  35% {text-shadow: 1px 0 #28a745, -1px 0 #28a745, 0 1px #28a745, 0 -1px #28a745, 1px 1px #28a745, -1px -1px #28a745, 1px -1px #28a745, -1px 1px #28a745;}
  40% {text-shadow: 1px 0 #20c997, -1px 0 #20c997, 0 1px #20c997, 0 -1px #20c997, 1px 1px #20c997, -1px -1px #20c997, 1px -1px #20c997, -1px 1px #20c997;}
  45% {text-shadow: 1px 0 #17a2b8, -1px 0 #17a2b8, 0 1px #17a2b8, 0 -1px #17a2b8, 1px 1px #17a2b8, -1px -1px #17a2b8, 1px -1px #17a2b8, -1px 1px #17a2b8;}
  50% {text-shadow: 1px 0 #6c757d, -1px 0 #6c757d, 0 1px #6c757d, 0 -1px #6c757d, 1px 1px #6c757d, -1px -1px #6c757d, 1px -1px #6c757d, -1px 1px #6c757d;}
  55% {text-shadow: 1px 0 #343a40, -1px 0 #343a40, 0 1px #343a40, 0 -1px #343a40, 1px 1px #343a40, -1px -1px #343a40, 1px -1px #343a40, -1px 1px #343a40;}
  60% {text-shadow: 1px 0 #007bff, -1px 0 #007bff, 0 1px #007bff, 0 -1px #007bff, 1px 1px #007bff, -1px -1px #007bff, 1px -1px #007bff, -1px 1px #007bff;}
  65% {text-shadow: 1px 0 #6c757d, -1px 0 #6c757d, 0 1px #6c757d, 0 -1px #6c757d, 1px 1px #6c757d, -1px -1px #6c757d, 1px -1px #6c757d, -1px 1px #6c757d;}
  70% {text-shadow: 1px 0 #28a745, -1px 0 #28a745, 0 1px #28a745, 0 -1px #28a745, 1px 1px #28a745, -1px -1px #28a745, 1px -1px #28a745, -1px 1px #28a745;}
  75% {text-shadow: 1px 0 #17a2b8, -1px 0 #17a2b8, 0 1px #17a2b8, 0 -1px #17a2b8, 1px 1px #17a2b8, -1px -1px #17a2b8, 1px -1px #17a2b8, -1px 1px #17a2b8;}
  80% {text-shadow: 1px 0 #ffc107, -1px 0 #ffc107, 0 1px #ffc107, 0 -1px #ffc107, 1px 1px #ffc107, -1px -1px #ffc107, 1px -1px #ffc107, -1px 1px #ffc107;}
  85% {text-shadow: 1px 0 #dc3545, -1px 0 #dc3545, 0 1px #dc3545, 0 -1px #dc3545, 1px 1px #dc3545, -1px -1px #dc3545, 1px -1px #dc3545, -1px 1px #dc3545;}
  90% {text-shadow: 1px 0 #343a40, -1px 0 #343a40, 0 1px #343a40, 0 -1px #343a40, 1px 1px #343a40, -1px -1px #343a40, 1px -1px #343a40, -1px 1px #343a40;}
  95% {text-shadow: 1px 0 #28a745, -1px 0 #28a745, 0 1px #28a745, 0 -1px #28a745, 1px 1px #28a745, -1px -1px #28a745, 1px -1px #28a745, -1px 1px #28a745;}
  100% {text-shadow: 1px 0 #20c997, -1px 0 #20c997, 0 1px #20c997, 0 -1px #20c997, 1px 1px #20c997, -1px -1px #20c997, 1px -1px #20c997, -1px 1px #20c997;}
}

@keyframes borderChange {
  0% {text-shadow: 1px 0 #007bff, -1px 0 #007bff, 0 1px #007bff, 0 -1px #007bff, 1px 1px #007bff, -1px -1px #007bff, 1px -1px #007bff, -1px 1px #007bff;}
  5% {text-shadow: 1px 0 #6610f2, -1px 0 #6610f2, 0 1px #6610f2, 0 -1px #6610f2, 1px 1px #6610f2, -1px -1px #6610f2, 1px -1px #6610f2, -1px 1px #6610f2;}
  10% {text-shadow: 1px 0 #6f42c1, -1px 0 #6f42c1, 0 1px #6f42c1, 0 -1px #6f42c1, 1px 1px #6f42c1, -1px -1px #6f42c1, 1px -1px #6f42c1, -1px 1px #6f42c1;}
  15% {text-shadow: 1px 0 #e83e8c, -1px 0 #e83e8c, 0 1px #e83e8c, 0 -1px #e83e8c, 1px 1px #e83e8c, -1px -1px #e83e8c, 1px -1px #e83e8c, -1px 1px #e83e8c;}
  20% {text-shadow: 1px 0 #dc3545, -1px 0 #dc3545, 0 1px #dc3545, 0 -1px #dc3545, 1px 1px #dc3545, -1px -1px #dc3545, 1px -1px #dc3545, -1px 1px #dc3545;}
  25% {text-shadow: 1px 0 #fd7e14, -1px 0 #fd7e14, 0 1px #fd7e14, 0 -1px #fd7e14, 1px 1px #fd7e14, -1px -1px #fd7e14, 1px -1px #fd7e14, -1px 1px #fd7e14;}
  30% {text-shadow: 1px 0 #ffc107, -1px 0 #ffc107, 0 1px #ffc107, 0 -1px #ffc107, 1px 1px #ffc107, -1px -1px #ffc107, 1px -1px #ffc107, -1px 1px #ffc107;}
  35% {text-shadow: 1px 0 #28a745, -1px 0 #28a745, 0 1px #28a745, 0 -1px #28a745, 1px 1px #28a745, -1px -1px #28a745, 1px -1px #28a745, -1px 1px #28a745;}
  40% {text-shadow: 1px 0 #20c997, -1px 0 #20c997, 0 1px #20c997, 0 -1px #20c997, 1px 1px #20c997, -1px -1px #20c997, 1px -1px #20c997, -1px 1px #20c997;}
  45% {text-shadow: 1px 0 #17a2b8, -1px 0 #17a2b8, 0 1px #17a2b8, 0 -1px #17a2b8, 1px 1px #17a2b8, -1px -1px #17a2b8, 1px -1px #17a2b8, -1px 1px #17a2b8;}
  50% {text-shadow: 1px 0 #6c757d, -1px 0 #6c757d, 0 1px #6c757d, 0 -1px #6c757d, 1px 1px #6c757d, -1px -1px #6c757d, 1px -1px #6c757d, -1px 1px #6c757d;}
  55% {text-shadow: 1px 0 #343a40, -1px 0 #343a40, 0 1px #343a40, 0 -1px #343a40, 1px 1px #343a40, -1px -1px #343a40, 1px -1px #343a40, -1px 1px #343a40;}
  60% {text-shadow: 1px 0 #007bff, -1px 0 #007bff, 0 1px #007bff, 0 -1px #007bff, 1px 1px #007bff, -1px -1px #007bff, 1px -1px #007bff, -1px 1px #007bff;}
  65% {text-shadow: 1px 0 #6c757d, -1px 0 #6c757d, 0 1px #6c757d, 0 -1px #6c757d, 1px 1px #6c757d, -1px -1px #6c757d, 1px -1px #6c757d, -1px 1px #6c757d;}
  70% {text-shadow: 1px 0 #28a745, -1px 0 #28a745, 0 1px #28a745, 0 -1px #28a745, 1px 1px #28a745, -1px -1px #28a745, 1px -1px #28a745, -1px 1px #28a745;}
  75% {text-shadow: 1px 0 #17a2b8, -1px 0 #17a2b8, 0 1px #17a2b8, 0 -1px #17a2b8, 1px 1px #17a2b8, -1px -1px #17a2b8, 1px -1px #17a2b8, -1px 1px #17a2b8;}
  80% {text-shadow: 1px 0 #ffc107, -1px 0 #ffc107, 0 1px #ffc107, 0 -1px #ffc107, 1px 1px #ffc107, -1px -1px #ffc107, 1px -1px #ffc107, -1px 1px #ffc107;}
  85% {text-shadow: 1px 0 #dc3545, -1px 0 #dc3545, 0 1px #dc3545, 0 -1px #dc3545, 1px 1px #dc3545, -1px -1px #dc3545, 1px -1px #dc3545, -1px 1px #dc3545;}
  90% {text-shadow: 1px 0 #343a40, -1px 0 #343a40, 0 1px #343a40, 0 -1px #343a40, 1px 1px #343a40, -1px -1px #343a40, 1px -1px #343a40, -1px 1px #343a40;}
  95% {text-shadow: 1px 0 #28a745, -1px 0 #28a745, 0 1px #28a745, 0 -1px #28a745, 1px 1px #28a745, -1px -1px #28a745, 1px -1px #28a745, -1px 1px #28a745;}
  100% {text-shadow: 1px 0 #20c997, -1px 0 #20c997, 0 1px #20c997, 0 -1px #20c997, 1px 1px #20c997, -1px -1px #20c997, 1px -1px #20c997, -1px 1px #20c997;}
}

/*--------------------------------------------------------------
  # Skills
  --------------------------------------------------------------*/
.skills ul {
  list-style: none;
  padding: 0;
}

.skills ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.skills ul strong {
  margin-right: 10px;
}

.skills ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #2B9EB3;
  line-height: 0;
}

.skillspdbtm {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 0px !important;
}

/*--------------------------------------------------------------
  # Interests
  --------------------------------------------------------------*/
.interests .icon-box {
  display: flex;
  align-items: center;
  padding: 20px;
  transition: ease-in-out 0.3s;
}

.interests .icon-box i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 1;
}

.interests .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;

}

/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/
.resume .resume-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}

.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  position: relative;
}

.resume .resume-item h4 {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #2B9EB3;
  margin-bottom: 10px;
}

.resume .resume-item h5 {
  font-size: 16px;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #2B9EB3;
  border: 2px solid #2B9EB3;
}


/*.bacgrounddede {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: repeating-linear-gradient(45deg, transparent, transparent 2em, papayawhip 0, papayawhip 4em), repeating-linear-gradient(-45deg, transparent, transparent 2em, seashell 0, seashell 4em), snow;
  background-blend-mode: multiply;
}*/