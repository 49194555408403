.backgroundpageabtme::before {
  /*background: #040404 url("../images/Nature.jpg") center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;*/
  content: "";
  position: fixed;
  background: #040404 url("../images/Nature.jpg") center center no-repeat;
  background-size: cover;
  left: 0;
  right: 0;
  top: 0;
  min-height: 100vh;
  z-index: -1;
}

/*Init*/
.raindroplines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 100vw;
  z-index: -1
}

.raindroplines .raindrop {
  position: absolute;
  width: 1.5px;
  height: 100%;
  top: 0;
  left: 50%;
  overflow: hidden;
}

.raindroplines .raindrop::after {
  content: "";
  display: block;
  position: absolute;
  height: 4vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(75%, #fff), to(#fff));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #c4d3df 75%, #C4D3DF 100%);
  -webkit-animation: rain 7s 0s infinite;
  animation: rain 7s 0s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(.4, .26, 0, .97);
  animation-timing-function: cubic-bezier(.4, .26, 0, .97)
}

.raindroplines .raindrop:nth-child(1) {
  margin-left: -45%
}

.raindroplines .raindrop:nth-child(1)::after {
  -webkit-animation-delay: .5s;
  animation-delay: .5s
}

.raindroplines .raindrop:nth-child(3) {
  margin-left: 45%
}

.raindroplines .raindrop:nth-child(3)::after {
  -webkit-animation-delay: 1s;
  animation-delay: 1s
}

.raindroplines .raindrop:nth-child(4) {
  margin-left: 40%
}

.raindroplines .raindrop:nth-child(4)::after {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s
}

.raindroplines .raindrop:nth-child(5) {
  margin-left: -40%
}

.raindroplines .raindrop:nth-child(5)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s
}

.raindroplines .raindrop:nth-child(6) {
  margin-left: 35%
}

.raindroplines .raindrop:nth-child(6)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s
}

.raindroplines .raindrop:nth-child(7) {
  margin-left: -35%
}

.raindroplines .raindrop:nth-child(7)::after {
  -webkit-animation-delay: 3s;
  animation-delay: 3s
}

.raindroplines .raindrop:nth-child(8) {
  margin-left: 30%
}

.raindroplines .raindrop:nth-child(8)::after {
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s
}

.raindroplines .raindrop:nth-child(9) {
  margin-left: -30%
}

.raindroplines .raindrop:nth-child(9)::after {
  -webkit-animation-delay: 4s;
  animation-delay: 4s
}

.raindroplines .raindrop:nth-child(10) {
  margin-left: 25%
}

.raindroplines .raindrop:nth-child(10)::after {
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s
}

.raindroplines .raindrop:nth-child(11) {
  margin-left: -25%
}

.raindroplines .raindrop:nth-child(11)::after {
  -webkit-animation-delay: 5s;
  animation-delay: 5s
}

.raindroplines .raindrop:nth-child(12) {
  margin-left: 20%
}

.raindroplines .raindrop:nth-child(12)::after {
  -webkit-animation-delay: 5.5s;
  animation-delay: 5.5s
}

.raindroplines .raindrop:nth-child(13) {
  margin-left: -20%
}

.raindroplines .raindrop:nth-child(13)::after {
  -webkit-animation-delay: 6s;
  animation-delay: 6s
}

.raindroplines .raindrop:nth-child(14) {
  margin-left: 15%
}

.raindroplines .raindrop:nth-child(14)::after {
  -webkit-animation-delay: 6.5s;
  animation-delay: 6.5s
}

.raindroplines .raindrop:nth-child(15) {
  margin-left: -15%
}

.raindroplines .raindrop:nth-child(15)::after {
  -webkit-animation-delay: 7s;
  animation-delay: 7s
}

.raindroplines .raindrop:nth-child(16) {
  margin-left: 10%
}

.raindroplines .raindrop:nth-child(16)::after {
  -webkit-animation-delay: 7.5s;
  animation-delay: 7.5s
}

.raindroplines .raindrop:nth-child(17) {
  margin-left: -10%
}

.raindroplines .raindrop:nth-child(17)::after {
  -webkit-animation-delay: 8s;
  animation-delay: 8s
}

.raindroplines .raindrop:nth-child(18) {
  margin-left: 5%
}

.raindroplines .raindrop:nth-child(18)::after {
  -webkit-animation-delay: 8.5s;
  animation-delay: 8.5s
}

.raindroplines .raindrop:nth-child(19) {
  margin-left: -5%
}

.raindroplines .raindrop:nth-child(19)::after {
  -webkit-animation-delay: 9s;
  animation-delay: 9s
}

@-webkit-keyframes rain {
  0% {
    top: -50%
  }

  100% {
    top: 110%
  }
}

@keyframes rain {
  0% {
    top: -50%
  }

  100% {
    top: 110%
  }
}