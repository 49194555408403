@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body {
  padding: 0;
  margin: 0;
  font-family: 'Lexend Deca', sans-serif;
  background-color: #040404;
  background: transparent;
}

body::before {
  content: "";
  position: fixed;
  background: #040404 url("./assets/images/MainForest.svg") center center no-repeat;
  background-size: cover;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*SCROLL BAR CSS only chrome*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #54577C;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #EDF5FC;
}

/*TEXT SELECTION CSS*/
::-moz-selection {
  background-color: #FA6862;
  color: #fff;
}
::selection {
  background-color: #FA6862;
  color: #fff;
}

/*BUTTONS TO PALLETES, BOOTSTRAP MOD*/
.btn {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.btn-border {
  border: 1px solid #d7d8db;
  display: inline-block;
  padding: 7px;
}

.btn:hover,
.btn:focus,
.btn:active:focus {
  outline: 0 none;
}

.btn-primary {
  background-color: #FA6862;
  border: 0;
  letter-spacing: 1px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus {
  background-color: #f9423a;
}


/*BOOTSRAP FORM CONTROL FOCUS CHANGE*/
.form-control:focus {
  border: 0.5px solid #54577C;
  box-shadow: none;
  background-color: #EDF5FC;
  letter-spacing: 1px;
  border-width: 1.5px;
  animation: 9s colorChange alternate infinite;
  -webkit-animation: 9s colorChange alternate infinite;
  -moz-animation: 9s colorChange alternate infinite;
  -o-animation: 9s colorChange alternate infinite;
}

@-webkit-keyframes colorChange {
  0% { border-color: #007bff; }
  5% { border-color: #6610f2; }
  10% { border-color: #6f42c1; }
  15% { border-color: #e83e8c; }
  20% { border-color: #dc3545; }
  25% { border-color: #fd7e14; }
  30% { border-color: #ffc107; }
  35% { border-color: #28a745; }
  40% { border-color: #20c997; }
  45% { border-color: #17a2b8; }
  50% { border-color: #6c757d; }
  55% { border-color: #343a40; }
  60% { border-color: #007bff; }
  65% { border-color: #6c757d; }
  70% { border-color: #28a745; }
  75% { border-color: #17a2b8; }
  80% { border-color: #ffc107; }
  85% { border-color: #dc3545; }
  90% { border-color: #343a40; }
  95% { border-color: #28a745; }
  100% { border-color: #20c997; }
}
@keyframes colorChange {
  0% { border-color: #007bff; }
  5% { border-color: #6610f2; }
  10% { border-color: #6f42c1; }
  15% { border-color: #e83e8c; }
  20% { border-color: #dc3545; }
  25% { border-color: #fd7e14; }
  30% { border-color: #ffc107; }
  35% { border-color: #28a745; }
  40% { border-color: #20c997; }
  45% { border-color: #17a2b8; }
  50% { border-color: #6c757d; }
  55% { border-color: #343a40; }
  60% { border-color: #007bff; }
  65% { border-color: #6c757d; }
  70% { border-color: #28a745; }
  75% { border-color: #17a2b8; }
  80% { border-color: #ffc107; }
  85% { border-color: #dc3545; }
  90% { border-color: #343a40; }
  95% { border-color: #28a745; }
  100% { border-color: #20c997; }
}
@-moz-keyframes colorChange {
  0% { border-color: #007bff; }
  5% { border-color: #6610f2; }
  10% { border-color: #6f42c1; }
  15% { border-color: #e83e8c; }
  20% { border-color: #dc3545; }
  25% { border-color: #fd7e14; }
  30% { border-color: #ffc107; }
  35% { border-color: #28a745; }
  40% { border-color: #20c997; }
  45% { border-color: #17a2b8; }
  50% { border-color: #6c757d; }
  55% { border-color: #343a40; }
  60% { border-color: #007bff; }
  65% { border-color: #6c757d; }
  70% { border-color: #28a745; }
  75% { border-color: #17a2b8; }
  80% { border-color: #ffc107; }
  85% { border-color: #dc3545; }
  90% { border-color: #343a40; }
  95% { border-color: #28a745; }
  100% { border-color: #20c997; }
}

.linknocss {
  text-decoration: none;
  color: inherit;
}
.linknocss:hover {
  color: inherit;
}