.bgpagelogin {
  min-height: calc(100vh - (56px + 0px));
}

.logindivcard {
  width: 100%;
  max-width: 400px;
  padding-left: 20px;
  padding-right: 20px;
}

.linklogin{
  color: #54577C;
}

.linklogin:hover{
  color: #2B9EB3;
}