.loadingbckgrnd {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadinglightbck {
  background: #cbcbcb;
}
.loadingdarkbck {
  background: #121212;
}

.loadingtxtdiv {
  text-align: center;
  user-select: none;
}

.loadinglightbck .loadingtxtdiv .ltr-loadingcomp:before {
  animation: loadingltrs-animation 4s infinite;
  -webkit-animation: loadingltrs-animation 4s infinite;
  -moz-animation: loadingltrs-animation 4s infinite;
  content: attr(data-lightloader);
  left: 0;
  opacity: 0;
  top: 0;
  position: absolute;
  background-color: #666666;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 3px 3px;
  font-size: calc(3vw + 3vh);
}
.loadinglightbck .loadingtxtdiv .ltr-loadingcomp {
  font-weight: 700;
  letter-spacing: 15px;
  display: inline-block;
  position: relative;
  font-size: calc(3vw + 3vh);
  color: rgba(43, 158, 179, 0.15);
}
.loadingdarkbck .loadingtxtdiv .ltr-loadingcomp:before {
  animation: loadingltrs-animation 4s infinite;
  -webkit-animation: loadingltrs-animation 4s infinite;
  -moz-animation: loadingltrs-animation 4s infinite;
  content: attr(data-lightloader);
  left: 0;
  opacity: 0;
  top: 0;
  position: absolute;
  color: #0f0f0f;
  text-shadow: -1px -1px 3px #020202, 2px 2px 4px #1b1b1b;
  -webkit-text-shadow: -1px -1px 3px #020202, 2px 2px 4px #1b1b1b;
  -moz-text-shadow: -1px -1px 3px #020202, 2px 2px 4px #1b1b1b;
}
.loadingdarkbck .loadingtxtdiv .ltr-loadingcomp {
  font-weight: 700;
  letter-spacing: 15px;
  display: inline-block;
  position: relative;
  font-size: calc(3vw + 3vh);
  color: rgba(43, 158, 179, 0.15);
}

.loadingtxtdiv .ltr-loadingcomp:nth-child(2):before {
  animation-delay: 0.2s;
}
.loadingtxtdiv .ltr-loadingcomp:nth-child(3):before {
  animation-delay: 0.4s;
}
.loadingtxtdiv .ltr-loadingcomp:nth-child(4):before {
  animation-delay: 0.6s;
}
.loadingtxtdiv .ltr-loadingcomp:nth-child(5):before {
  animation-delay: 0.8s;
}
.loadingtxtdiv .ltr-loadingcomp:nth-child(6):before {
  animation-delay: 1s;
}
.loadingtxtdiv .ltr-loadingcomp:nth-child(7):before {
  animation-delay: 1.2s;
}
.loadingtxtdiv .ltr-loadingcomp:nth-child(8):before {
  animation-delay: 1.4s;
}

@keyframes loadingltrs-animation {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes loadingltrs-animation {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@-moz-keyframes loadingltrs-animation {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}